html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

img {
  max-width: 100%;
}

pre {
  background: #eee;
  color: #363940;
  font-family: monospace;
  font-size: 2rem;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}
